import React from 'react'
import Layout from 'layouts/default'
import Policies from 'components/policies'

const Privacy = () => (
  <Layout path="/policies/privacy" subheader="policies">
    <Policies policy="privacy" />
  </Layout>
)

export default Privacy
